import React, { useEffect, useState } from "react";
import './App.css';

// I'm not very big on frontend so I'm focusing more on the systems assignment.

function App() {
  const [posts, setPosts] = useState();

  const fetchPosts = async() => {
    const response = await fetch("https://alexb-cloudflare.amacb.workers.dev/posts").then(
      (response) => (response.json())
    );

    console.log(response);
    setPosts(response);
  }
  
  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="App">
      {posts &&
        posts.map((post) => (
          <div class="card">
            <hr></hr>
            <h2>{post.title}</h2>
            <h3>{post.username}</h3>
            <p>{post.content}</p>
          </div>
        ))}
    </div>
  );
}

export default App;
